import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useBreakpointUp } from 'qualification/hooks/useBreakpoints';
import BenefitIcons from 'shared/components/BenefitIcons';
import { BENEFITS } from 'shared/constants';

const benefits = [
  createBenefit('nr1', BenefitIcons.NotebookIcon, {
    height: 60,
    width: 60,
  }),
  createBenefit(
    ['customerCount', { count: '13.500' }],
    BenefitIcons.CustomerIcon,
    { height: 50, width: 50 }
  ),
  createBenefit(
    ['catererCount', { count: BENEFITS.CATERER_COUNT }],
    BenefitIcons.ShefIcon,
    {
      height: 55,
      width: 55,
    }
  ),
  createBenefit(
    ['satisfaction', { percent: BENEFITS.CUSTOMER_SATISFACTION_PERCENT }],
    BenefitIcons.FiveStarIcon,
    {
      height: 54,
      width: 70,
    }
  ),
  createBenefit('personalService', BenefitIcons.CheckmarkIcon, {
    height: 50,
    width: 50,
  }),
  createBenefit('quickAndFree', BenefitIcons.ShefIcon, {
    height: 55,
    width: 55,
  }),
];

const BenefitsGrid = () => {
  const isDesktop = useBreakpointUp('md');
  const { t } = useTranslation('dynamic_landing_page');

  return (
    <Grid>
      {benefits.map(({ labelTranslation, ImageComponent, size }) => (
        <Box
          as="li"
          maxWidth="280px"
          textAlign="center"
          key={
            typeof labelTranslation === 'string'
              ? labelTranslation
              : labelTranslation[0]
          }
        >
          <IconWrapper>
            <ImageComponent
              height={isDesktop ? size.height : size.height * 0.9}
              width={isDesktop ? size.width : size.width * 0.9}
              alt=""
            />
          </IconWrapper>
          <TypographyBenefit>
            {Array.isArray(labelTranslation)
              ? t(...labelTranslation)
              : t(labelTranslation)}
          </TypographyBenefit>
        </Box>
      ))}
    </Grid>
  );
};

const IconWrapper = styled.div`
  height: 50px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 60px;
  }
`;

const Grid = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  grid-column-gap: ${({ theme }) => theme.spacing(3)}px;
  grid-row-gap: ${({ theme }) => theme.spacing(4)}px;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: start;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: ${({ theme }) => theme.spacing(8)}px;
    grid-row-gap: ${({ theme }) => theme.spacing(2)}px;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-column-gap: ${({ theme }) => theme.spacing(3)}px;
  }
`;

const TypographyBenefit = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 168%;
  max-width: 130px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 16px;
    max-width: 150px;
  }
`;

function createBenefit(
  labelTranslation: string | [string, any],
  ImageComponent: (props: any) => JSX.Element,
  size: { height: number; width: number }
) {
  if (Array.isArray(labelTranslation)) {
    labelTranslation[0] = `common:benefits.${labelTranslation[0]}`;
  } else {
    labelTranslation = `common:benefits.${labelTranslation}`;
  }

  return {
    labelTranslation,
    ImageComponent,
    size,
  };
}

export default BenefitsGrid;
