import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import Image, { ImageProps } from 'next/image';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import BenefitsGrid from 'shared/components/BenefitsGrid';
import { createSizes } from 'shared/helpers/createSizes';
import Container from 'system/components/Container';

const Benefits = () => {
  const { t } = useTranslation('common');

  return (
    <Container size="md">
      <Wrapper>
        <Box width="100%">
          <Title
            px={3}
            pl={{ xs: 3, md: 3 }}
            pt={{ xs: 2, md: 0 }}
            variant="h4"
            component="h3"
            mb={{ xs: 3, sm: 4, md: 6 }}
          >
            {t('benefits.title.part1')} {t('benefits.title.part2')}
          </Title>
          <Box maxWidth={{ md: '600px' }}>
            <BenefitsGrid />
          </Box>
        </Box>
        <Box
          position="relative"
          flexShrink={0}
          height="fit-content"
          width={{ xs: 'min(300px, 80vw)', md: 'min(40vw, 550px)' }}
        >
          <ImageWithEllipse
            width={550}
            height={460}
            sizes={createSizes({ xs: '300px', md: '500px' })}
            layout="responsive"
            src="/artifacts/images/homepage/benefits-illustration.webp"
          />
        </Box>
      </Wrapper>
    </Container>
  );
};

const ImageWithEllipse = (props: ImageProps) => {
  return (
    <>
      <BackgroundEllipseWrapper>
        <Image
          layout="fill"
          alt=""
          src="/artifacts/images/homepage/benefits-background-ellipse.webp"
        />
      </BackgroundEllipseWrapper>
      <Image alt="" {...props} />
    </>
  );
};

const Title = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  line-height: 160%;

  text-align: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 36px;
    text-align: start;
  }
`;

const BackgroundEllipseWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  transform: scaleY(1.15) translateX(2%) translateY(2%);
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
  padding: ${({ theme }) => theme.spacing(2, 2, 3, 2)};
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(5)}px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    gap: ${({ theme }) => theme.spacing(10)}px;
    padding: ${({ theme }) => theme.spacing(2, 5, 5, 2)};
    flex-direction: row;
  }
`;

export default Benefits;
